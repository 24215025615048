import { graphql } from 'gatsby';
import * as React from 'react';
import LayoutHeaderAndFooter from '../components/layout-header-and-footer';
import { Link } from 'gatsby';
import { padding10 } from '../styles/class-names';
import Seo from '../components/seo';

export default function NotFound({ data }: { data: any }) {
	const image = data.site.siteMetadata.siteUrl + '/img/photos/profiles/under-desk.gif';
	return (
		<LayoutHeaderAndFooter>
			<Seo title="Error, File Not Found" description="Unfortunately the requested file no longer exists on this server" imagePath={image} url="https://michaeljbennett.info/404"/>
			<article className={padding10}>
				<h2>Page Not Found</h2>
				Sorry, the page you're looking for does not exist, please&nbsp;
				<Link to="/">head home</Link>
			</article>
		</LayoutHeaderAndFooter>
	);
}

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`
